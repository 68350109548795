.pagination {
    width: 600px;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pagination__item {
    font-size: 20px;
    border: 2px solid rgb(135, 116, 225);
    background-color: white;
    border-radius: .5rem;
    color: black;
    padding: 1rem;
    margin: .25rem;
}

.pagination__item:hover {
    background-color: rgb(135, 116, 225);
    cursor: pointer;
}

.pagination__item--selected {
    font-weight: 800;
}

.pagination__item--disabled {
    color: grey;
}

.pagination__item--disabled:hover {
    cursor: not-allowed;
    background-color: white;
}
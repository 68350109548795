.messages {
    max-width: 800px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 130px);
    overflow: scroll;
}

.messages > * {
    margin-bottom: 0.5rem;
}
.message {
    padding: .1rem 0.5rem .1rem;
    box-shadow: 0 1px 2px rgba(16, 16, 16, 0.611765);
    display: inline-block;
    max-width: 60%;
    border-radius: 0.75rem;
    color: white;
    font-size: 1.25rem;
}

.message--left {
    background: rgb(135, 116, 225);
}

.message--right {
    background: rgb(33, 33, 33);
    align-self: end;
}